import React, { useState, useEffect } from 'react';
import { Box, IconButton, FormControlLabel, Alert, Add as AddIcons } from '@mui/material';
import {Add as AddIcon, Edit as EditIcon, 
  Delete as DeleteIcon, Layers as CategoryIcon} from '@mui/icons-material';
  
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import DataGridStyle from '../data-table/datagrid_style';
import CustomButton from "../common/button_one";
import ModelPopup from "../common/modal_popup";
import SwitchStyle from '../SwitchStyle';
import { basic } from '../../../themes/basic';
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';
import HeadingWithStep from './heading_steps';

const Product = () => {

  const [__productData, __setProductData] = useState([]);
  const [__selectedProduct, __setSelectedProduct] = useState([]);
  const __navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [messageStatus, setMessageStatus] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [descriptionMessage, setDescriptionMessage] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");

  const [__statusChangeModalOpen, __setStatusChangeModalOpen] = useState(false);
  const [__statusChangeMessage, __setStatusChangeMessage] = useState(false);
  const [__statusChangeSelectedId, __setStatusChangeSelectedId] = useState(null);
  const [__statusChangeUpdatedValue, __setStatusChangeUpdatedValue] = useState(false);

  const selectedProductFunc = (selectedItems) => {
    __setSelectedProduct(selectedItems)
  };

  const __getAllProducts = async () => {
    //setLoading(true);
    const _getProducts = await axios.get("/product/getAllProducts");
    //setLoading(false);
    const _product_values = _getProducts?.data?.data?.product?.map((aItem) => ({
      ...aItem,
      isVisible:aItem.isVisible ? 'enable' : 'disable',
      id: aItem._id,
    }));
    __setProductData(_product_values);
  }

  const __moveToAddProductPage = () => {
    __navigate("/adminDashboard/product/add");
  }

  const __handleEditProduct = (aProductId) => {
    __navigate(`/adminDashboard/product/${aProductId}`);
  }

  const __handleDeleteProduct = async (aProductId,aProductName) => {
      setSnackbarOpen(false);
      setSelectedId(aProductId);
      const _notifyMessage = `Are you sure you want to delete <b>${aProductName}</b> product?`
      setDescriptionMessage(_notifyMessage);
      setModalOpen(true)
  }

  const __statusUpdateForProduct = async (aProductId,aProductName,aStatus) => {
    setSnackbarOpen(false);
    __setStatusChangeSelectedId(aProductId);
    __setStatusChangeUpdatedValue(aStatus);
    
    const _notifyMessage = `Are you sure you want to ${aStatus === false ? "disable" : "enable"} <b>${aProductName}</b> product?`
    __setStatusChangeMessage(_notifyMessage);
    __setStatusChangeModalOpen(true)
}

  const productDeleteConfirm = async () => {
    setModalOpen(false);
    setLoading(true);
    
    const deleteProductData = await axios.delete(`/product/delete/${selectedId}`);
    if(deleteProductData.data.status=="success"){
      __getAllProducts();
      setLoading(false);
      triggerSnackbar("Product deleted successfully.","success");
    }else{
      setLoading(false);
      triggerSnackbar("Failed to delete product.", "error");
    }
  }

  const __updateProductStatusFunc = async () => {
    __setStatusChangeModalOpen(false);
    setLoading(true);
    let _productData = {
      isVisible: __statusChangeUpdatedValue
    };
    const _updateProductData = await axios.put(`/product/updateStatus/${__statusChangeSelectedId}`, _productData);
    if(_updateProductData.data.status=="success"){      
      __getAllProducts();
      setLoading(false);
      triggerSnackbar(`Product has been ${__statusChangeUpdatedValue ? 'enabled' : 'disabled'} successfully.`,"success");
    }else{
      setLoading(false);
      triggerSnackbar("Failed to update the product.", "error");
    }
  };

  const handleClose = () => setModalOpen(false);

  const __handleStatusChangeClose = () => __setStatusChangeModalOpen(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    __setStatusChangeModalOpen(false);
  };

  const triggerSnackbar = (message, variant) => {
    setSnackbarMessage(message);
    setSnackbarVariant(variant);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    //sessionStorage.clear();
    const getSessionProductValue = sessionStorage.getItem("_selectedProductID");
    if(getSessionProductValue){
      //__setSelectedProduct([getSessionProductValue]);
    }else{
      sessionStorage.clear();
    }
    __getAllProducts();

    // Fetch products from API
  }, [selectedId]);

  

  return (
    <div style={{ marginTop: "0px", position:"relative" }}> 

      <HeadingWithStep title="Manage Product" step={0} />

      <div style={{ display: "flex", position:"absolute", marginTop: 13,zIndex:"1000" }}>
        <CustomButton
        onClick={__moveToAddProductPage}
        aria-label="Add Product"
        >
          <AddIcon fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-2}}/>
          <div style={{ marginLeft: 5 }}>Add Product</div>
        </CustomButton>
        
      </div>
      
      <Box
      sx={{
        height: "auto",
        width: "100%",
        ul: {
          bgcolor: "#f6f6f6",
          color: "#ffffff",
        },
      }}
    >
      <DataGridStyle
        rows={__productData}
        columns={[
          {
            field: "productName",
            headerName: "Name",
            minWidth: 150,
            flex: 1,
            headerAlign: "left",
           /* renderCell: (params) => {
              const handleProductClick = () => {
                sessionStorage.setItem("_selectedProductID", params.row.id);
               __navigate("/adminDashboard/variant");
              };
              return (
                <ClickableText title={params.row.productName} onClick={handleProductClick}>{params.row.productName}</ClickableText>
              );
            }, */
          },
          {
            field: "productDescription",
            headerName: "Description",
            minWidth: 600,
            flex: 3,
            headerAlign: "left",
          },
          {
            field: "isVisible",
            headerName: "Visible",
            maxWidth: 120,
            flex: 1,
            headerAlign: "left",
            renderCell: (params) => {
              const isActive = params.row.isVisible;
              return <FormControlLabel
                style={{ marginLeft: 0, fontSize:basic.contentFontSize }}
                sx={{ fontSize:basic.contentFontSize,

                  "& .MuiFormControlLabel-label": {
                    fontSize:basic.contentFontSize,
                    fontFamily:basic.fontFamily,
                  }
                }}
                control={
                  <SwitchStyle
                    size="small"
                    checked={isActive == "enable" ? true : false}
                    onChange={(e) => {
                      __statusUpdateForProduct(params.row.id,params.row.productName,e.target.checked)
                    }}
                  />
                }
                //label={isActive == "enable" ? "Enabled" : "Disabled"}
                />             
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            maxWidth: 120,
            headerAlign: "left",
            disableExport: true,
            sortable: false,
            renderCell: (params) => (
              <Box>
                <IconButton                  
                  onClick={() => { 
                    sessionStorage.setItem("_selectedProductID", params.row.id);
                    __navigate("/adminDashboard/variant");
                  }}
                >
                 <AddIcon titleAccess='Add Variant' style={{fontSize:basic.iconsFontSize}}/>
                </IconButton>
                <IconButton
                  aria-label="Edit"
                  onClick={() => __handleEditProduct(params.row._id)}
                >
                  <EditIcon titleAccess='Edit Product' title="Edit Product" style={{fontSize:basic.iconsFontSize}} />
                </IconButton>
                <IconButton
                  disabled
                  aria-label="Delete"
                  onClick={() => { 
                    setLoading(false);
                    __handleDeleteProduct(params.row._id,params.row.productName);
                  }}
                >
                  <DeleteIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Delete Product' />
                </IconButton>
              </Box>
            ),
          },
        ]}
        disableSelectionOnClick
        autoHeight={true}
        checkboxSelection={false}
        selectionModel={__selectedProduct}
        onSelectionModelChange={(selectedItems) => {
          selectedProductFunc(selectedItems)
        }}
      />
      </Box>
      

      <ModelPopup
        open={modalOpen}
        onClose={handleClose}
        onConfirm={productDeleteConfirm}
        title="Alert"
        description={descriptionMessage}        
        loading={loading}
        showIcon={false}
        icon={Alert}
        successMsg={successMsg}
        successStatus={messageStatus}
        confirmText="Confirm"
        cancelText="Cancel"
      />

      <ModelPopup
        open={__statusChangeModalOpen}
        onClose={__handleStatusChangeClose}
        onConfirm={__updateProductStatusFunc}
        title="Alert"
        showIcon={false}
        icon={Alert}
        description={__statusChangeMessage}
        loading={loading}
        confirmText="Confirm"
        cancelText="Cancel"
      />

      <LoadingOverlay loading={loading} />

      <MessageSnackbar
        message={snackbarMessage}
        variant={snackbarVariant}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />

    </div>
  );
};

export default Product;
