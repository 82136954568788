import React, { useState, useEffect, Fragment  } from 'react';
import { Button, Box, IconButton,Typography,FormControlLabel, Alert } from '@mui/material';
import {Add as AddIcon, Edit as EditIcon, 
  Delete as DeleteIcon, Layers as CategoryIcon} from '@mui/icons-material';
import axios from 'axios';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DataGridStyle from '../data-table/datagrid_style';
import CustomButton  from '../common/button_one';
import BackButton from '../common/back_button';
import SwitchStyle from '../SwitchStyle';
import { basic } from '../../../themes/basic';
import ModelPopup from "../common/modal_popup";
import LoadingOverlay from '../common/loading_overlay';
import MessageSnackbar from '../common/message_snackbar';
import HeadingWithStep from './heading_steps';
import ClickableText from '../common/clickable_text';

const Variant = () => {

  const [__variantData, __setVariantData] = useState([]);
  const [__selectedVariant, __setSelectedVariant] = useState([]);
  const __navigate = useNavigate() 
  const [__selectedProductName, __setSelectedProductName] = useState(null);
  const [__modalOpen, __setModalOpen] = useState(false);
  const [__loading, __setLoading] = useState(false);
  const [__descriptionMessage, __setDescriptionMessage] = useState(null);
  const [__successMsg, __setSuccessMsg] = useState(null);
  const [__messageStatus, __setMessageStatus] = useState(null);
  const [__deleteVariantId, __setDeleteVariantId] = useState(null);
  const [__deleteVariantName, __setDeleteVariantName] = useState(null);
  const [__selectedProductId, __setSelectedProductId] = useState([]);

  const [__snackbarOpen, __setSnackbarOpen] = useState(false);
  const [__snackbarMessage, __setSnackbarMessage] = useState("");
  const [__snackbarVariant, __setSnackbarVariant] = useState("success");

  //status update
  const [__statusChangeModalOpen, __setStatusChangeModalOpen] = useState(false);
  const [__statusChangeMessage, __setStatusChangeMessage] = useState(false);
  const [__statusChangeSelectedId, __setStatusChangeSelectedId] = useState(null);
  const [__statusChangeUpdatedValue, __setStatusChangeUpdatedValue] = useState(false);

  const __handleClose = () => __setModalOpen(false);

  const __setSelectedVariantFunc = (aSelectedVariant) => {
    __setSelectedVariant(aSelectedVariant)
  };

  const __navigateToAddVariantPage = () => {
    __navigate("/adminDashboard/variant/add");
  }

  const __handleEditVariant = (aVariantId) => {
    __navigate(`/adminDashboard/variant/${aVariantId}`);
  }

  const __handleDeleteVariant = async (aVariantId,aVariantName) => {
      __setSnackbarOpen(false);
      __setDeleteVariantId(aVariantId);
      const _notifyMessage = `Are you sure you want to delete <b>${aVariantName}</b> variant?`
      __setDescriptionMessage(_notifyMessage);
      __setModalOpen(true)
  }

  const __statusUpdateForVariant = async (aVariantId,aVariantName,aStatus) => {
    __setSnackbarOpen(false);
    __setStatusChangeSelectedId(aVariantId);
    __setStatusChangeUpdatedValue(aStatus);
    const _notifyMessage = `Are you sure you want to ${aStatus === false ? "disable" : "enable"} <b>${aVariantName}</b> variant?`
    __setStatusChangeMessage(_notifyMessage);
    __setStatusChangeModalOpen(true)
  }


  const __assignCourses = () => {
    if (__selectedVariant?.length == 1) {
      sessionStorage.removeItem("_selectedVariantID");
      sessionStorage.setItem("_selectedVariantID", __selectedVariant);
      __navigate("/adminDashboard/course")
    }
  }

  // get product details using by product id
  const __getProductDataById = async (aSelectedProductId) => {
    const _getSelectedVariant = await axios.get(`/product/${aSelectedProductId}`);
    __setSelectedProductName(_getSelectedVariant.data.data.productName);

    const _variant_value = _getSelectedVariant.data.data.productAssociation?.map((aItem) => ({
      productName: _getSelectedVariant.data.data.productName,
      variantName:aItem.variantId?.variantName,
      variantDescription:aItem?.variantId?.variantDescription,
      isVisible:aItem?.isVisible ? 'enable' : 'disable',
      id: aItem?.variantId?._id,
    }));
    __setVariantData(_variant_value);
    console.log(_variant_value);
  }

  const __updateVariantStatusFunc = async () => {
    __setStatusChangeModalOpen(false);
    __setLoading(true);
    let _variantData = {
      isVisible: __statusChangeUpdatedValue,
      productId:__selectedProductId
    };
    const _updateVariantDataStatus = await axios.put(`/variant/updateStatus/${__statusChangeSelectedId}`, _variantData);
    if(_updateVariantDataStatus.data.status=="success"){
      //__getVariantData();
      __setLoading(false);
      __getProductDataById(__selectedProductId);
      __triggerSnackbar(`Variant has been ${__statusChangeUpdatedValue ? 'enabled' : 'disabled'} successfully.`,"success");
    }else{
      __setLoading(false);
      __triggerSnackbar("Failed to update the variant.","error"); 
    }
  };

  const __variantDeleteConfirm = async () => {
    __setModalOpen(false);
    __setLoading(true);    
    const deleteProductData = await axios.delete(`/variant/delete/${__deleteVariantId}/${__selectedProductId}`);
    if(deleteProductData.data.status=="success"){
      __setModalOpen(false);
      __setLoading(false); 
      __getProductDataById(__selectedProductId);
      __triggerSnackbar("Variant deleted successfully.","success"); 
      //__getVariantData();
      //__setMessageStatus("success");
     // __setSuccessMsg("Variant deleted successfully.");
    }else{
      __setLoading(false); 
      __triggerSnackbar("Failed to delete product.","error"); 
      //__setMessageStatus("failed");
      //__setSuccessMsg("Failed to delete product.");
    }
  }

  const __handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    __setSnackbarOpen(false);
  };

  const __handleStatusChangeClose = () => __setStatusChangeModalOpen(false);

  const __triggerSnackbar = (message, variant) => {
    __setSnackbarMessage(message);
    __setSnackbarVariant(variant);
    __setSnackbarOpen(true);
  };


  useEffect(() => {
    //sessionStorage.clear();
    const _getSessionVariantValue = sessionStorage.getItem("_selectedVariantID");
    if(_getSessionVariantValue){
      //__setSelectedVariant([_getSessionVariantValue]);
    }

    // To display the selected product name using by product id
    const _getSelectedProductId = sessionStorage.getItem("_selectedProductID");
    if(_getSelectedProductId){
      __setSelectedProductId(_getSelectedProductId);
      __getProductDataById(_getSelectedProductId);
      //__getVariantData(_getSelectedProductId);
    }

  
  }, []);

  const steps = ["Products", "Variants", "Courses"];

  return (
    <div style={{ marginTop: "0", position:"relative" }}> 

      <HeadingWithStep title="Manage Product" step={1} />
  
      <Fragment>
        <Typography
            style={{
              fontSize: basic.headingFontSize,
              marginBottom: 0,
              marginTop: 10,
              fontWeight: 500,
              textAlign: "left",
              fontFamily:basic.fontFamily
            }}
          >
            <strong style={{color: "#90bb41"}}>{__selectedProductName}</strong>
          </Typography>
      </Fragment>

      <div style={{ display: "flex", position:"absolute", marginTop: 18, zIndex:"1000" }}>
        <BackButton backToPage="/adminDashboard/product" aButtonText="Back" />
        <CustomButton
        onClick={__navigateToAddVariantPage}
        aria-label="Add Variant"
        >
          <AddIcon fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-2}}/>
          <div style={{ marginLeft: 5 }}>Add Variant</div>
        </CustomButton>
        
      </div>
      
      <Box
      sx={{
        height: "auto",
        width: "100%",
        ul: {
          bgcolor: "#f6f6f6",
          color: "#ffffff",
        },
      }}
    >
      <DataGridStyle
        rows={__variantData}
        columns={[
          {
            field: "productName",
            headerName: "Product Name",
            hide: true, 
          },
          //Above Product Name field will hide from the grid but it will still be in the export
          {
            field: "variantName",
            headerName: "Variant Name",
            minWidth: 150,
            flex: 1,
            headerAlign: "left",
          },
          {
            field: "variantDescription",
            headerName: "Variant Description",
            minWidth: 600,
            flex: 3,
            headerAlign: "left",
          },
          {
            field: "isVisible",
            headerName: "Visible",
            maxWidth: 110,
            flex: 1,
            headerAlign: "left",
            renderCell: (params) => {
              const isActive = params.row.isVisible;
              return <FormControlLabel
                style={{ marginLeft: 0, fontSize:basic.contentFontSize }}
                sx={{ fontSize:basic.contentFontSize,

                  "& .MuiFormControlLabel-label": {
                    fontSize:basic.contentFontSize,
                    fontFamily:basic.fontFamily,
                  }
                }}
                control={
                  <SwitchStyle
                    size="small"
                    //disabled
                    checked={isActive === "enable" ? true : false}
                    onChange={(e) => {
                      __statusUpdateForVariant(params.row.id,params.row.variantName,e.target.checked)
                    }}
                  />
                }
                //label={isActive === "enable" ? "Enabled" : "Disabled"}
                />
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            maxWidth: 120,
            headerAlign: "left",
            disableExport: true,
            sortable: false,
            renderCell: (params) => (
              <Box>
                 <IconButton                  
                  onClick={() => { 
                    sessionStorage.setItem("_selectedVariantID", params.row.id);
                    __navigate("/adminDashboard/course");       
                  }}
                >
                 <AddIcon titleAccess='Add Course' style={{fontSize:basic.iconsFontSize}} />
                </IconButton>
                <IconButton
                  aria-label="Edit"
                  onClick={() => __handleEditVariant(params.row.id)}
                >
                  <EditIcon titleAccess='Edit Variant' style={{fontSize:basic.iconsFontSize}}/>
                </IconButton>
                <IconButton
                  disabled
                  aria-label="Delete"
                  onClick={() => {
                    __handleDeleteVariant(params.row.id,params.row.variantName)}
                  }
                >
                  <DeleteIcon titleAccess='Delete Variant' style={{fontSize:basic.iconsFontSize}} />
                </IconButton>
              </Box>
            ),
          },
        ]}
        disableSelectionOnClick
        autoHeight={true}
        checkboxSelection={false}
        selectionModel={__selectedVariant}
        onSelectionModelChange={(aSelectedItems) => {
          __setSelectedVariantFunc(aSelectedItems)
        }}
        getRowClassName={(params) =>
          __selectedVariant.includes(params.row._id) ? "highlight-row" : ""
        }
      />
      </Box>

      {/* delete product modal */}
      <ModelPopup
        open={__modalOpen}
        onClose={__handleClose}
        onConfirm={__variantDeleteConfirm}
        title="Alert"
        description={__descriptionMessage}
        loading={__loading}
        successMsg={__successMsg}
        successStatus={__messageStatus}
        confirmText="Confirm"
        cancelText="Cancel"
      />

      {/* status update modal */}
      <ModelPopup
        open={__statusChangeModalOpen}
        onClose={__handleStatusChangeClose}
        onConfirm={__updateVariantStatusFunc}
        title="Alert"
        showIcon={false}
        icon={Alert}
        description={__statusChangeMessage}
        loading={__loading}
        confirmText="Confirm"
        cancelText="Cancel"
      />

      <LoadingOverlay loading={__loading} />
      <MessageSnackbar
        message={__snackbarMessage}
        variant={__snackbarVariant}
        open={__snackbarOpen}
        onClose={__handleSnackbarClose}
      />

    </div>
  );
};

export default Variant;
